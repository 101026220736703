@import "../theme/variables";

//
// Media queries
// -------------
//

//
// 0px    320px          576px     768px       992px    1200px
// |--------|-------------|---------|----------|---------|--------
// |   xs   |  "default"  |    sm   |    md    |   lg    |   xl
//
@mixin media-breakpoint-xs {
  @media screen and (max-width: map-get($breakpoints, xs)) {
    @content;
  }
}

@mixin media-breakpoint-sm {
  @media screen and (min-width: map-get($breakpoints, sm)) and (max-width: map-get($breakpoints, md) - 1) {
    @content;
  }
}

@mixin media-breakpoint-md {
  @media screen and (min-width: map-get($breakpoints, md)) and (max-width: map-get($breakpoints, lg) - 1) {
    @content;
  }
}

@mixin media-breakpoint-lg {
  @media screen and (min-width: map-get($breakpoints, md)) and (max-width: map-get($breakpoints, xl) - 1) {
    @content;
  }
}

@mixin media-breakpoint-xl {
  @media screen and (min-width: map-get($breakpoints, xl)) {
    @content;
  }
}

@mixin media-breakpoint-up($name) {
  $br: map-get($breakpoints, $name);
  @if not $br {
    @error "breakpoint `#{$name}` not found in breakpoints";
  }

  @media screen and (min-width: $br) {
    @content;
  }
}

@mixin media-breakpoint-down($name) {
  $br: map-get($breakpoints, $name);
  @if not $br {
    @error "breakpoint `#{$name}` not found in breakpoints";
  }

  @media screen and (max-width: $br - 1px) {
    @content;
  }
}

@mixin media-mobile-only {
  @media screen and (max-width: map-get($breakpoints, lg) - 1px) {
    @content;
  }
}

@mixin media-desktop-only {
  @media screen and (min-width: map-get($breakpoints, lg)) {
    @content;
  }
}

@mixin max-width-centered-desktop() {
  max-width: var(--desktop-max-width);
  margin: 0 auto;
}

//
// Form controls & input
// ---------------------
//

@mixin input-border() {
  border: 1px solid var(--ion-color-light);
}

@mixin input-border-radius($important: false) {
  @if $important {
    border-radius: var(--input-border-radius) !important;
  } @else {
    border-radius: var(--input-border-radius);
  }
}

@mixin input-error-border($important: false) {
  @if $important {
    border: 1px solid var(--ion-color-danger) !important;
  } @else {
    border: 1px solid var(--ion-color-danger);
  }
}

@mixin input-focused() {
  border: 1px solid var(--ion-color-light-shade);
}

@mixin has-error() {
  &.ion-invalid.ion-dirty,
  &.ion-invalid:not(.ion-untouched) {
    @content;
  }
}

@mixin has-focus() {
  &.has-focus,
  &.item-has-focus {
    @content;
  }
}

@mixin visually-hidden() {
  position: absolute !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  border: 0 !important;
}

@mixin transition-property($property) {
  transition-property: $property;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

@mixin transition-all() {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
