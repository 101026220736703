@import "./functions";

:root {
  --ion-color-primary: #2c59c8;
  --ion-color-primary-rgb: 44, 89, 200;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #274eb0;
  --ion-color-primary-tint: #416ace;
  /*for date-range picker*/
  --ion-color-primary-light: #6688d8;
  --ion-color-primary-lighter: #a0b6e8;

  --ion-color-secondary: #2c59c8;
  --ion-color-secondary-rgb: 44, 89, 200;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #274eb0;
  --ion-color-secondary-tint: #416ace;

  --ion-color-tertiary: #2c59c8;
  --ion-color-tertiary-rgb: 44, 89, 200;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #274eb0;
  --ion-color-tertiary-tint: #416ace;

  --ion-color-success: #0d6759;
  --ion-color-success-rgb: 13, 103, 89;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0b5b4e;
  --ion-color-success-tint: #25766a;

  --ion-color-warning: #e06c00;
  --ion-color-warning-rgb: 224, 108, 0;
  --ion-color-warning-contrast: #fff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c55f00;
  --ion-color-warning-tint: #e37b1a;

  --ion-color-danger: #cd2026;
  --ion-color-danger-rgb: 205, 32, 38;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b41c21;
  --ion-color-danger-tint: #d2363c;

  --ion-color-dark: #1b211e;
  --ion-color-dark-rgb: 27, 33, 30;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #181d1a;
  --ion-color-dark-tint: #323735;

  --ion-color-medium: #68687a;
  --ion-color-medium-rgb: 104, 104, 122;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #5c5c6b;
  --ion-color-medium-tint: #777787;

  --ion-color-light: #f5f6f8;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8d8da;
  --ion-color-light-tint: #f6f7f9;

  --ion-font-family: "Inter";
}

/** Custom CSS variables **/
:root {
  /** Color **/
  --ion-color-lightblue: #eeeff1;
  --ion-color-darkblue: #4a4c69;
  --ion-color-danger-light: #fae9e9;
  --ion-color-danger-rgba: rgba(205, 32, 38, 0.2);
  --ion-color-white: #ffffff;
  --ion-color-pattensblue: #e0e2e3;
  --ion-color-disabled: #88889c;
  --ion-color-border-light: #e4e5ea;
  --ion-color-rose: #ff3144;

  --ion-color-active: #2c59c8;
  --ion-color-active-rgb: 44, 89, 200;
  --ion-color-active-contrast: #ffffff;
  --ion-color-active-contrast-rgb: 255, 255, 255;
  --ion-color-active-shade: #274eb0;
  --ion-color-active-tint: #416ace;

  --ion-color-status-approved: #0d6759;
  --ion-color-status-approved-rgb: 13, 103, 89;
  --ion-color-status-approved-contrast: #ffffff;
  --ion-color-status-approved-contrast-rgb: 255, 255, 255;
  --ion-color-status-approved-shade: #0b5b4e;
  --ion-color-status-approved-tint: #25766a;

  --ion-color-status-cancelled: #cd2026;
  --ion-color-status-cancelled-rgb: 205, 32, 38;
  --ion-color-status-cancelled-contrast: #ffffff;
  --ion-color-status-cancelled-contrast-rgb: 255, 255, 255;
  --ion-color-status-cancelled-shade: #b41c21;
  --ion-color-status-cancelled-tint: #d2363c;

  --ion-color-status-pending: #e06c00;
  --ion-color-status-pending-rgb: 224, 108, 0;
  --ion-color-status-pending-contrast: #fff;
  --ion-color-status-pending-contrast-rgb: 0, 0, 0;
  --ion-color-status-pending-shade: #c55f00;
  --ion-color-status-pending-tint: #e37b1a;

  --ion-color-status-completed: #68687a;
  --ion-color-status-completed-rgb: 104, 104, 122;
  --ion-color-status-completed-contrast: #ffffff;
  --ion-color-status-completed-contrast-rgb: 255, 255, 255;
  --ion-color-status-completed-shade: #5c5c6b;
  --ion-color-status-completed-tint: #777787;

  // ihz colors
  --ion-ihz-color-active: #2f5dc5;
  --ion-ihz-color-active-contrast: #eff2fb;

  // Changed by js based on user.high_contrast
  --ion-color-input-border: var(--ion-color-pattensblue);

  --ion-toolbar-background: var(--ion-color-white);
  --ion-tab-bar-background: var(--ion-color-white);

  --ion-padding: 1rem;

  /** Border-radius **/
  --ion-border-radius: 12px;
  --ion-border-radius-sm: 8px;

  /** Font sizes **/
  --font-base: #{to-rem(16px)};
  --font-xxs: #{to-rem(12px)};
  --font-xs: #{to-rem(13px)};
  --font-sm: #{to-rem(14px)};
  --font-md: #{to-rem(18px)};
  --font-lg: #{to-rem(20px)};
  --font-xl: #{to-rem(24px)};
  --font-xxl: #{to-rem(28px)};
  --font-xxxl: #{to-rem(36px)};

  /** Font line height **/
  --line-height-base: #{to-rem(24px)};
  --line-height-xxs: #{to-rem(18px)};
  --line-height-xs: #{to-rem(20px)};
  --line-height-sm: #{to-rem(22px)};
  --line-height-md: #{to-rem(27px)};
  --line-height-lg: #{to-rem(28px)};
  --line-height-xl: #{to-rem(30px)};
  --line-height-xxl: #{to-rem(35px)};
  --line-height-xxxl: #{to-rem(48px)};

  /** Font weight **/

  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /** Misc **/
  --desktop-max-width: 1920px;
  --desktop-side-menu-width: 300px;
  --desktop-header-height: 64px;
  --mobile-item-height: 56px;
  --tabs-item-height: 50px;
  --tabs-item-padding-y: 14px;
  --tabs-item-border-size: 1px;
  --tabs-height: calc(
    var(--tabs-item-height) + 2 * var(--tabs-item-padding-y) +
      var(--tabs-item-border-size)
  );
}

// color
// ----
.ion-color-active {
  --ion-color-base: var(--ion-color-active);
  --ion-color-base-rgb: var(--ion-color-active-rgb);
  --ion-color-contrast: var(--ion-color-active-contrast);
  --ion-color-contrast-rgb: var(--ion-color-active-contrast-rgb);
  --ion-color-shade: var(--ion-color-active-shade);
  --ion-color-tint: var(--ion-color-active-tint);
}

.ion-color-status-approved {
  --ion-color-base: var(--ion-color-status-approved);
  --ion-color-base-rgb: var(--ion-color-status-approved-rgb);
  --ion-color-contrast: var(--ion-color-status-approved-contrast);
  --ion-color-contrast-rgb: var(--ion-color-status-approved-contrast-rgb);
  --ion-color-shade: var(--ion-color-status-approved-shade);
  --ion-color-tint: var(--ion-color-status-approved-tint);
}

.ion-color-status-cancelled {
  --ion-color-base: var(--ion-color-status-cancelled);
  --ion-color-base-rgb: var(--ion-color-status-cancelled-rgb);
  --ion-color-contrast: var(--ion-color-status-cancelled-contrast);
  --ion-color-contrast-rgb: var(--ion-color-status-cancelled-contrast-rgb);
  --ion-color-shade: var(--ion-color-status-cancelled-shade);
  --ion-color-tint: var(--ion-color-status-cancelled-tint);
}

.ion-color-status-pending {
  --ion-color-base: var(--ion-color-status-pending);
  --ion-color-base-rgb: var(--ion-color-status-pending-rgb);
  --ion-color-contrast: var(--ion-color-status-pending-contrast);
  --ion-color-contrast-rgb: var(--ion-color-status-pending-contrast-rgb);
  --ion-color-shade: var(--ion-color-status-pending-shade);
  --ion-color-tint: var(--ion-color-status-pending-tint);
}

.ion-color-status-completed {
  --ion-color-base: var(--ion-color-status-completed);
  --ion-color-base-rgb: var(--ion-color-status-completed-rgb);
  --ion-color-contrast: var(--ion-color-status-completed-contrast);
  --ion-color-contrast-rgb: var(--ion-color-status-completed-contrast-rgb);
  --ion-color-shade: var(--ion-color-status-completed-shade);
  --ion-color-tint: var(--ion-color-status-completed-tint);
}

// Grid
// ----
$breakpoints: (
  xs: 320px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
