@import "../mixins";

//
// General theming and overriding of basic ionic
// input components.
// -----------------------------------------------
//

.#{$root_class_name} {
  ion-input,
  ion-textarea,
  ion-select {
    font-size: var(--font-sm);
    border: 1px var(--ion-color-input-border) solid;
    border-radius: var(--ion-border-radius);
    --placeholder-color: var(--ion-color-medium);
    --placeholder-opacity: 1;
    --padding-bottom: 1rem !important;
    --padding-end: 1rem !important;
    --padding-start: 1rem !important;
    --padding-top: 1rem !important;
    overflow: hidden;

    @include has-error {
      border-color: var(--ion-color-danger);
      --placeholder-color: var(--ion-color-danger);
    }

    &:focus-visible {
      border-color: var(--ion-color-primary);
    }
  }

  .has-focus {
    border-color: var(--ion-color-primary) !important;
  }

  ion-item {
    --background-focused: var(--ion-color-light);
    --background-focused-opacity: 0.5;
    --background-activated: var(--ion-color-primary);
    --border-color: var(--ion-color-border-light);
  }

  ion-select {
    &::part(text),
    &::part(placeholder) {
      padding-right: 20px;
    }

    // This is workaround to change default icon. Basically it just
    // display our desired icon over original one which stays hidden.
    &::part(icon) {
      visibility: hidden;
      opacity: 1;

      &::after {
        visibility: visible;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("/assets/icon/chevron-right.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 6px 11px;
      }
    }

    @include has-error {
      &::part(icon) {
        opacity: 0.4;
      }
    }
  }

  ion-input.search-input {
    --background: var(--ion-color-light);
    --padding-bottom: 0px !important;
    --padding-top: 0px !important;
    --padding-start: 12px !important;
    border-radius: 36px;
  }

  ion-item {
    @include has-error {
      ion-label {
        color: var(--ion-color-danger);
      }
    }
  }

  .form-label {
    font-size: var(--font-xxs);
    line-height: var(--line-height-xxs);
    font-weight: var(--font-weight-semibold);
  }

  .form-error-label {
    color: var(--ion-color-danger);
    margin-top: 10px;
    text-overflow: clip;
    white-space: normal;
  }

  ion-label.required::after {
    content: "*";
    color: #fe0900;
    font-size: 1.5em;
    line-height: 0;
    top: 3px;
    position: relative;
    margin-left: 3px;
  }

  .form-item {
    --inner-border-width: 0;
    --padding-start: 0;
    --inner-padding-end: 0;
    margin-top: 10px;
    margin-bottom: 1rem;

    &.disable-effects {
      --background-hover-opacity: 0;
      --background-focused-opacity: 0;
      --background-activated-opacity: 0;
    }
  }

  ion-checkbox {
    --border-color: var(--ion-color-input-border);
  }

  ion-toggle {
    --track-background: var(--ion-color-input-border);
  }
  ion-radio-group ion-item {
    --border-color: var(--ion-color-input-border);
  }
}
