@import "../mixins";
//
// General flink modal styling
// ----------------------------
ion-modal.fl-modal {
  --max-width: 600px;

  ion-button[size="large"] {
    @media screen and (max-width: 380px) {
      --padding-start: 1rem;
      --padding-end: 1rem;
    }
  }
}

//
// Displayed as bottom sheet on mobile and
// centered on screen on desktop
// ----------------------------
ion-modal.bottom-sheet-modal {
  @include media-breakpoint-down(lg) {
    --border-radius: 30px 30px 0 0;
    align-items: flex-end;
  }

  @include media-breakpoint-up(lg) {
    --border-radius: var(--ion-border-radius);
  }
}

//
// General auto height
// ---------------------
ion-modal.auto-height {
  --height: auto;
  --max-height: 100%;

  @include media-breakpoint-down(lg) {
    .inner-content {
      max-height: 80vh;
      overflow: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    .inner-content {
      overflow: auto;
    }
  }

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      padding: 32px 16px;
      text-align: center;
    }
  }
}

//
// General auto width
// ---------------------

ion-modal.auto-width {
  --width: auto;
}

//
// Gallery slideshow modal
// -------------------
ion-modal.fl-gallery-slideshow-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

//
// Options modal
// -------------------
ion-modal.fl-options {
  --backdrop-opacity: 0.3;
}

//
// Confirmation modals
// -------------------
ion-modal.fl-leave-dirty-form {
  --height: 300px;
  --max-height: 300px;
}

ion-modal.parking-reservation-select-kanton-modal {
  --height: 440px;
  --max-height: 440px;
}

ion-modal.second-level-modal::part(backdrop) {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

ion-modal.pdf-modal {
  --width: 100%;
  --max-width: 800px;
  --height: 100%;
  --border-radius: 0;
}
