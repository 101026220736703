ion-calendar {
  padding: 10px 0 !important;

  ion-calendar-month {
    .button.days-btn p {
      font-size: var(--font-base);
    }
    .days-box {
      padding: 0 !important;
    }

    .startSelection:before,
    .between button.days-btn,
    .on-selected,
    .startSelection button.days-btn,
    .endSelection button.days-btn,
    .endSelection:before {
      background-color: var(--ion-color-active) !important;
    }
  }

  ion-calendar-week ion-toolbar ul {
    padding-bottom: 24px;
    li {
      font-size: 13px;
      text-transform: uppercase;
      color: var(--ion-color-medium);
      font-weight: var(--font-weight-semibold);
      letter-spacing: -0.078px;
    }
  }
}

ion-calendar button {
  color: var(--color);
  &:focus {
    border: 1px solid var(--ion-color-active);
  }
}

ion-button.switch-btn {
  color: var(--ion-color-dark) !important;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-base) !important;
  line-height: var(--line-height-lg) !important;
}
