ion-segment {
  border-radius: 0;
  --background: var(--ion-color-white);
  min-height: 40px;
  border-bottom: 1px solid var(--ion-color-border-light);

  ion-segment-button {
    --background: transparent;
    --background-checked: transparent;
    --color-checked: var(--ion-color-secondary);
    --border-radius: 0;
    --indicator-box-shadow: none;
    --border-width: 0;
    border-bottom: 2px solid transparent;
    &.segment-button-checked {
      border-bottom: 2px solid var(--ion-color-secondary);
    }
  }
}

ion-segment.segment-with-bg {
  border-radius: 8px;
  --background: var(--ion-color-input-border);
  padding: 3px;

  ion-segment-button {
    font-size: var(--font-sm);
    --border-radius: 8px;
    --color: var(--segment-button-color, var(--ion-color-medium));
    --color-checked: var(--ion-color-dark);
    --border-width: 0;
    border-bottom: none;
    &.segment-button-checked {
      box-shadow: 0px 1px 8px rgba(99, 103, 103, 0.2);
    }
  }
}
