ion-button[size="small"],
ion-button.button-small {
  --border-radius: var(--ion-border-radius-sm);
  height: 30px;
}

ion-button {
  height: 52px;
  --border-radius: var(--ion-border-radius);
}

.btn-pattensblue-border {
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-pattensblue);
}
