@import "../mixins";
@import "../functions";

//
// Common styles
// -------------
// For various classes that doesn't fit anywhere else
//
* {
  margin: 0;
  padding: 0;
}

ion-app {
  margin-left: auto;
  margin-right: auto;
  display: block;

  // Force light mode
  color-scheme: light;
}

.#{$root_class_name} {
  .border {
    border: 1px var(--ion-color-border-light) solid;
    border-radius: var(--ion-border-radius);

    @include has-error {
      border-color: var(--ion-color-danger);
    }
  }

  ion-header ion-toolbar:last-of-type {
    --border-width: 0 0 1px;
  }
}

ion-toolbar {
  display: flex;
  align-items: center;
  --border-color: var(--ion-color-border-light);
  --padding-top: 0;
  // border bottom is inset
  --padding-bottom: 1px;
  --padding-start: 0;
  --padding-end: 0;
  padding-right: 0;
  padding-left: 0;
}

html.ios ion-modal ion-toolbar {
  padding-left: 0;
  padding-right: 0;
}

.title-large {
  font-size: var(--font-xxl);
  font-weight: var(--font-weight-bold) !important;
}

.header-collapse-condense ion-toolbar:first-of-type {
  padding-top: 0;
}

hr {
  background: var(--ion-color-border-light);
}

ion-toast {
  --color: var(--ion-color-danger) !important;
  --white-space: pre-line !important;
}

svg-icon.round-with-bg {
  background: var(--ion-color-light);
  box-sizing: content-box;
  padding: 8px;
  border-radius: 50%;
}

.markdown-content {
  hr {
    margin: #{to-rem(16px)} 0;
  }

  p {
    margin-bottom: #{to-rem(10px)};
    line-height: var(--line-height-base);
    font-size: var(--font-size-base);
  }

  ul,
  ol {
    margin: #{to-rem(16px)} 0;
    margin-left: #{to-rem(16px)};
    li {
      margin-bottom: #{to-rem(10px)};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h3 {
    font-weight: var(--font-weight-semibold);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: #{to-rem(10px)};
  }
}

ion-item.reservable-item {
  --padding-start: 0;
  --inner-padding-end: 0;
  font-size: var(--font-base);
  line-height: var(--line-height-base);
  font-weight: var(--font-weight-medium);
  --inner-border-width: 0;
  align-items: flex-start;
  margin-bottom: 1rem;
  --min-height: none;

  svg-icon {
    font-size: var(--font-xl) !important;
    margin-right: 1rem;
    color: var(--ion-color-medium);
    min-width: 24px;
  }

  p {
    margin-bottom: 0;
  }
}

iframe {
  // without it payment iframe is not transparent
  color-scheme: light;
}

ion-refresher {
  // because we have custom parent relative element and the refresher height is 60px
  top: -60px;
}
