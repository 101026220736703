.horizontal-scroll-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  align-items: flex-end;

  &::-webkit-scrollbar {
    display: none;
  }

  .horizontal-scroll-item {
    flex: 0 0 auto;
  }
}
