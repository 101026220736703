@import "../mixins";

#CookiebotWidget {
  display: none !important;
}

#CybotCookiebotDialog {
  // Workaround for development environment:
  // Hide the TEST div with specific inline styles
  > div[style*="position: absolute"][style*="font-size: 100px"][style*="opacity: 0.25"] {
    display: none !important;
  }
}

// Hide the default Cookiebot button
#CookiebotWidget .CookiebotWidget-logo {
  display: none !important;
}

// Hide powered by link
#CybotCookiebotDialogPoweredbyLink {
  display: none !important;
}

#CybotCookiebotDialogPoweredbyCybot {
  justify-content: flex-start !important;
}

body.cookiebot-overlay::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  pointer-events: none;
}
