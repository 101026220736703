ion-fab {
  &.desktop {
    margin-bottom: 1rem;

    // Because fab-button aligns to whole viewport and we want to align it with main
    // content in the middle, little trick is introduced:
    // fab-button has flResponsiveCols directive which make its width to be same as
    // side column thus moving it closer to main column. Now we need to add negative margin
    // which is same of width of the ion-fab-button. Voila, now it appears align to the right
    // side of the main column.
    ion-fab-button {
      margin-left: -56px;
    }
  }
}
