.#{$root_class_name} {
  .sc-ion-popover-ios-h {
    --ion-backdrop-opacity: 0.4;
  }
  .popover-arrow::after {
    background: rgb(var(--ion-background-color-rgb, 255, 255, 255)) !important;
  }
}

//
// Desktop header popover menu
// ---------------------------
ion-popover.fl-markdown-cheat-sheet {
  // --width: auto;
  --min-width: 80vw;
  --max-width: 350px;
  --background: transparent;
  --ion-safe-area-right: 10px;
}
