.fl-loading {
  --background: transparent;
  --spinner-color: var(--ion-color-dark);
  --backdrop-opacity: 0.3;

  .loading-wrapper {
    box-shadow: none !important;
  }

  ion-spinner {
    width: 64px;
    height: 64px;
  }
}
