@import "../variables";
@import "../mixins";

$columns: 12;

@each $br, $width in $breakpoints {
  @for $span from 1 to $columns {
    @include media-breakpoint-up($br) {
      .col-#{$br}-#{$span} {
        flex: 0 0 calc(calc($span / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc($span / var(--ion-grid-columns, 12)) * 100%);
        max-width: calc(calc($span / var(--ion-grid-columns, 12)) * 100%);
      }
    }
  }

  .col-#{$br}-0 {
    width: inherit;
    max-width: inherit;
  }
}
