.toast {
  @include media-breakpoint-down(sm) {
    // Mobile constraints (up to 576px)
    --max-width: 90vw !important;
    --width: 90vw !important;
  }

  @include media-breakpoint-up(sm) {
    // Tablet and Desktop - flexible width based on content
    --max-width: min(90vw, 600px) !important;
    --width: max-content !important;
  }

  white-space: pre-wrap !important;
  word-wrap: break-word !important;

  &::part(message) {
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
  }
}

.toast--messenger {
  @extend .toast;
  font-weight: var(--font-weight-bold);
  top: -160px;
  @include media-breakpoint-up(sm) {
    top: -70px;
  }
}

.toast--connection-error {
  @extend .toast;
  --height: 44px;
  --border-radius: 8px;
  top: -80px;

  @include media-breakpoint-up(lg) {
    top: -14px;
  }

  &::part(button) {
    height: 32px;
    padding: 0 8px;
    margin: 0 10px;
    border: none;
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    border-radius: 4px;
    font-weight: var(--font-weight-bold);
    font-size: 14px;
    text-transform: none;

    &:hover {
      background: var(--ion-color-primary-shade);
    }
  }

  &::part(message) {
    font-size: 14px;
    font-weight: var(--font-weight-bold);
  }
}
