html {
  font-size: 100%;
}

body {
  font-size: var(--font-base);
  font-weight: 400;
  color: var(--ion-color-dark);
}

h1,
.h1 {
  font-size: var(--font-xxl);
  line-height: var(--line-height-xxl);
  font-weight: var(--font-weight-bold);
  margin: 0;
}

h2,
.h2 {
  font-size: var(--font-xl);
  line-height: var(--line-height-xl);
  font-weight: var(--font-weight-bold);
  margin: 0;
}

h3,
.h3 {
  font-size: var(--font-md);
  line-height: var(--line-height-md);
  font-weight: 400;
}

small,
.small {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}

a,
.a {
  cursor: pointer;
}
