/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

$root_class_name: "fl-app" !default;

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* App */
@import "./theme/functions";

@import "./theme/common/common";
@import "./theme/common/grid";
@import "./theme/common/typography";
@import "./theme/common/utilities";
@import "./theme/common/loading";
@import "./theme/common/horizontal-scrolling";

@import "./theme/overrides/buttons";
@import "./theme/overrides/calendar";
@import "./theme/overrides/cookiebot";
@import "./theme/overrides/fab";
@import "./theme/overrides/icons";
@import "./theme/overrides/inputs";
@import "./theme/overrides/popover";
@import "./theme/overrides/modals";
@import "./theme/overrides/segment";
@import "./theme/overrides/toasts";

@import "@ctrl/ngx-emoji-mart/picker";
